





































import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'
import { IDocument } from '@store/documents/Interface'
import { IProject } from '@store/user/Interface'
import { userNamespace } from '@store/user'

interface IComment {
  comment: string
}

@Component
export default class FormComment extends Vue {
  @Prop({ default: () => null }) private doc!: IDocument

  @Prop({ default: false }) private edit!: boolean

  @Prop({ default: '' }) private comment!: string

  @Prop({ default: false }) private loading!: boolean

  @userNamespace.Getter('project')
  private selectedProject!: IProject

  private formComment: IComment = {
    comment: '',
  }

  get disabledCategory() {
    if (this.selectedProject.work_list_requested) {
      const arrId = [1, 2, 3]
      if (arrId.findIndex(item => item === this.doc.typeId) !== -1) {
        return true
      }
    }

    return false
  }

  private mounted() {
    if (this.doc) {
      this.formComment.comment = this.doc.comment
    } else {
      this.formComment.comment = this.comment
    }
  }

  private saveComment() {
    this.$emit('save-comment', this.formComment)
    this.formComment = {
      comment: '',
    };
  }

  private closeForm() {
    this.formComment = {
      comment: '',
    }

    this.$emit('close-dialog')
  }
}
